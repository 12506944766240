.instructors-page-container-instructor-image {
    width: 50%;
    height: auto;
}

.instructors-page-container-instructor-name {
    justify-content: center;
    font-size: 2em;
    margin-top: 20px;
    text-align: center;
}

.instructors-page-container-instructor-city {
    justify-content: center;
    font-size: 1.6em;
    margin-top: 0px;
    color: #02d1c7;
    text-align: center;
}

.instructors-page-container-instructor-col-row {
    height: 100%;
}

.instructors-page-container-instructor-contact {
    justify-content: center;
    margin-top: 20px;
    color: #02d1c7;
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
    
    div {
        font-size: 0.8em;
        width: 100%;
        text-align: center;
    }
}

.instructors-page-container-instructor-social-media {
    justify-content: center;
    margin-top: 10px;
    color: #02d1c7;
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: center;
}

.instructors-page-container-instructor-social-media-icon-left {
    margin-right: 5px;
    transition: all .2s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.instructors-page-container-instructor-social-media-icon-right {
    margin-left: 5px;
    transition: all .2s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.instructors-page-container-instructor-card {
    box-shadow: 0px 3px 30px rgba(0, 0, 0, .1);
}

.instructors-page-container-instructor-col {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;