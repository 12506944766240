.product-card {
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.product-card-image {
	height: 75%;
	width: 75%;
	box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.2);
	text-align: center !important;
	justify-content: center !important;

	img {
		border-radius: 5px !important;
	}

	@media screen and (max-width: 768px) {
		height: 30%;
		width: 30%;
	}

	@media screen and (max-width: 576px) {
		height: 40%;
		width: 40%;
	}
}

.product-card-count-button {
	height: 30px !important;
	width: 30px !important;
	color: #02d1c7 !important;
	background-color: white !important;
	border-color: white !important;
	font-size: 1em;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.3) !important;
	}
}

.product-card-count-text {
	font-size: 1.3em;
	margin-left: 15px;
	margin-right: 15px;
}

@primary-color: #02d1c7;@menu-collapsed-width: 40px;