.distributors-page-container-button-section {
    margin-top: 30px;
}

.distributors-page-container-collapse-button {
    border-color: #02d1c7;
	border-style: solid;
	border-width: 1px;
	color: #02d1c7;
	width: 120px;
	height: 35px;
	border-radius: 5px;
	font-weight: bold;
	text-align: center;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
		background-color: #02d1c7;
		color: white;
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1rem;
	}
}

.distributors-page-container-description-section {
    margin-top: 30px;
    font-size: 0.8rem;
    text-align: center;
}

.distributors-page-container-card {
    box-shadow: 0px 3px 30px rgba(0, 0, 0, .1);
    padding: 20px
}

.distributors-page-container-card-col {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
}

.distributors-page-container-card-image {
    width: 60%;
    height: auto;
    margin-bottom: 20px;
}

.distributors-page-container-divider {
    height: 1px;
    width: 5%;
    background-color: #02d1c7;
}

.distributors-page-container-title {
    font-size: 2.5em;
    margin-top: 100px;
    margin-bottom: 30px;
}

.distributors-page-container-description {
    text-align: center;
    margin-bottom: 10px;
}

.distributors-page-container-partners-divider {
    height: 1px;
    width: 5%;
    background-color: #02d1c7;
}

.distributors-page-container-partners-description {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 50px;
}

.distributors-page-container-card-col-row {
    height: 100%;
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;