.product-promotion {
	&__name {
		&__title {
			display: inline-block;
		}
		&__badge {
			display: inline-block;
		}
	}
}

@primary-color: #02d1c7;@menu-collapsed-width: 40px;