.steps-action {
	margin-bottom: 200px;
}

@media only screen and (max-width: 370px) {
	.checkout-stepper.ant-steps-small .ant-steps-item-title {
		font-size: 10px !important;
	}

	.checkout-stepper.ant-steps-vertical .ant-steps-item-icon {
		margin-right: 2px !important;
	}
}

@media only screen and (min-width: 371px) and (max-width: 600px) {
	.checkout-stepper.ant-steps-small .ant-steps-item-title {
		font-size: 11px !important;
	}

	.checkout-stepper.ant-steps-vertical .ant-steps-item-icon {
		margin-right: 8px !important;
	}
}

.cart-page-container-next-button {
	background-color: #02d1c7;
	border-color: #02d1c7;
	height: 40px !important;
	width: 140px !important;
	font-size: 1.3em !important;
	box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.12) !important;
	transition: all 0.2s ease-in-out;
	margin-left: 10px;

	button {
		background-color: transparent !important;
		color: white !important;
		border-color: transparent !important;
		font-size: 1.2em !important;
	}

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 315px) {
		margin-left: 0px;
	}
}

.cart-page-container-previous-button {
	background-color: #02d1c7;
	border-color: #02d1c7;
	height: 40px !important;
	width: 140px !important;
	font-size: 1.3em !important;
	box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.08) !important;
	transition: all 0.2s ease-in-out;
	margin-right: 10px;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 315px) {
		margin-right: 0px;
	}
}

.cart-page-container-order-button {
	background-color: #02d1c7;
	border-color: #02d1c7;
	height: 40px !important;
	width: 140px !important;
	font-size: 1.3em !important;
	box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.12) !important;
	transition: all 0.2s ease-in-out;
	margin-left: 10px;

	&:hover {
		transform: scale(1.1);
	}
}

@primary-color: #02d1c7;@menu-collapsed-width: 40px;