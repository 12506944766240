.about-us-page-container {
    margin-top: 100px;
}

.about-us-main-row {
    margin-left: 50px;
    margin-right: 50px;
}

.about-us-page-container-title {
    font-size: 2.5em;

    @media only screen and (max-width: 768px) {
		margin-left: auto;
        margin-right: auto;
        text-align: center !important;
	}
}

.about-us-page-container-subtitle {
    text-align: center;

    @media only screen and (max-width: 768px) {
        text-align: center;
	}
}
.about-us-page-container-title-left {
    font-size: 2.5em;

    @media only screen and (max-width: 768px) {
        justify-content: center;
	}
}

.about-us-page-container-title-divider {
	width: 5%;
	height: 2px;
    background-color: #02d1c7;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.about-us-page-container-title-divider-left {
	width: 20%;
	height: 1px;
    background-color: black;
    margin-bottom: 40px;

    @media only screen and (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.about-us-page-container-card-image {
    max-width: 100%;
}


.about-us-page-container-cards-section {
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #02d1c7;
}

.about-us-page-container-card-row {
    height: 100%;
}


.about-us-page-container-card {
    width: 60%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    padding-top: 40px;
}

.about-us-page-container-card-title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
}

.about-us-page-container-card-description {
    text-align: center;
}

.about-us-page-container-card-divider {
    width: 40%;
	height: 2px;
    background-color: black;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
}

.about-us-page-container-product-title {
    font-size: 1.5em;
    font-weight: 900;

    @media only screen and (max-width: 768px) {
        justify-content: center;
	}
}

.landing-section-product-image {
    @media only screen and (max-width: 768px) {
        margin-top: 50px !important;
	}
}

.about-us-page-container-product-col-left {
    padding-left: 100px;

    @media only screen and (max-width: 768px) {
        padding-left: 0px;
	}
}


.about-us-page-container-product-title-divider {
    width: 10%;
	height: 1px;
    background-color: black;
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
	}
}

.about-us-page-container-product-subtitle {
    width: 70%;

    @media only screen and (max-width: 768px) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
	}
}

.landing-section-product-image {
    width: 50%;
    height: 50%;
}

.about-us-page-container-product-row {
    margin-top: 100px;
    margin-bottom: 100px;
}

.landing-section-footer-image {
    // padding-top: 50px;
    // padding-bottom: 50px;
    width: 100%;
}

.about-us-page-container-footer-background {
    // background-color: #02d1c7;
    background-color: white;
    height: 100%;
}

.about-us-page-container-footer-inner-card {
    background-color: white;
    width: 80%;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.about-us-page-container-footer-inner-card-title {
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
    margin-bottom: 30px;
}

.about-us-page-container-footer-inner-card-description {
    text-align: center;
}

.about-us-page-container-product-col-1 {
    @media only screen and (max-width: 768px) {
        order: 1;
	}
}

.about-us-page-container-product-col-2 {
    @media only screen and (max-width: 768px) {
        order: 0;
	}
}

.about-us-page-container-footer-mobile-col {
    background-image: url('./assets/8.png');
    background-size: cover;
}

.about-us-page-container-footer-background-mobile {
    background-color: #02d1c749;
    height: 100%;
    padding-top: 300px;
    padding-bottom: 100px;
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;