.instructors-map-row {
	margin-top: 200px;
	margin-bottom: 150px;

	@media only screen and (max-width: 992px) {
		margin-top: 100px;
		margin-bottom: 100px;
	}
}

.map-section {
	margin-top: 60px;
	margin-bottom: 60px;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #f0f0f0;
}

.map-section-instructor-block-outer {
	background-color: transparent;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-color: #7de3df;
	width: 100%;
	border-width: 0.7px;

	@media only screen and (max-width: 992px) {
		padding-right: 10px;
	}
}

.map-section-instructor-block-inner {
	width: 100%;
	background-color: #7de3df;
}

.map-section-instructor-carousel {
	width: 100%;
	margin-top: 80px;
}

.map-section-instructor-block-image {
	width: 70%;
	height: 70%;
	margin-top: -30%;
	padding-bottom: 50px;

	max-height: 100%;
	max-width: 100%;

	@media only screen and (max-width: 992px) {
		margin-top: 30px;
		width: 50%;
		height: 50%;
	}
}

.map-section-instructor-block-header {
	text-align: center;
	font-size: 2em;
	font-family: 'Satisfy', cursive;
	font-weight: 100;

	@media only screen and (max-width: 992px) {
		margin-top: 20px;
	}
}

.map-section-instructor-block-description {
	text-align: center;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	font-size: 0.9em;
}

.map-section-instructor-block-button {
	border-style: solid;
	border-radius: 100px;
	border-width: 2px;
	padding: 5px 50px;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 30px;

	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}
}

.map-section-instructor-block-button-link {
	text-decoration: none !important;
	color: black !important;
}

.map-section-image {
	position: relative;
	-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
	-moz-transform: scale(0.2); /* FF3.5+ */
	-ms-transform: scale(0.2); /* IE9 */
	-o-transform: scale(0.2); /* Opera 10.5+ */
	transform: scale(0.2);
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(0.25);
	}

	&:focus {
		transform: scale(0.25);
	}

	@media only screen and (max-width: 1600px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.3);
		}

		&:focus {
			transform: scale(0.3);
		}
	}

	@media only screen and (max-width: 1200px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.3);
		}

		&:focus {
			transform: scale(0.3);
		}
	}

	@media only screen and (max-width: 992px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.3);
		}

		&:focus {
			transform: scale(0.3);
		}
	}

	@media only screen and (max-width: 768px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.3);
		}

		&:focus {
			transform: scale(0.3);
		}
	}

	@media only screen and (max-width: 576px) {
		-webkit-transform: scale(0.1); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.1); /* FF3.5+ */
		-ms-transform: scale(0.1); /* IE9 */
		-o-transform: scale(0.1); /* Opera 10.5+ */
		transform: scale(0.1);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 480px) {
		-webkit-transform: scale(0.1); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.1); /* FF3.5+ */
		-ms-transform: scale(0.1); /* IE9 */
		-o-transform: scale(0.1); /* Opera 10.5+ */
		transform: scale(0.1);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}
}

.map-section-image-grow {
	-webkit-transform: scale(0.25); /* Saf3.1+, Chrome */
	-moz-transform: scale(0.25); /* FF3.5+ */
	-ms-transform: scale(0.25); /* IE9 */
	-o-transform: scale(0.25); /* Opera 10.5+ */
	transform: scale(0.25);
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	@media only screen and (max-width: 1600px) {
		-webkit-transform: scale(0.3); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.3); /* FF3.5+ */
		-ms-transform: scale(0.3); /* IE9 */
		-o-transform: scale(0.3); /* Opera 10.5+ */
		transform: scale(0.3);
		transition: all 0.2s ease-in-out;
	}

	@media only screen and (max-width: 1200px) {
		-webkit-transform: scale(0.3); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.3); /* FF3.5+ */
		-ms-transform: scale(0.3); /* IE9 */
		-o-transform: scale(0.3); /* Opera 10.5+ */
		transform: scale(0.3);
		transition: all 0.2s ease-in-out;
	}

	@media only screen and (max-width: 992px) {
		-webkit-transform: scale(0.3); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.3); /* FF3.5+ */
		-ms-transform: scale(0.3); /* IE9 */
		-o-transform: scale(0.3); /* Opera 10.5+ */
		transform: scale(0.3);
		transition: all 0.2s ease-in-out;
	}

	@media only screen and (max-width: 768px) {
		-webkit-transform: scale(0.3); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.3); /* FF3.5+ */
		-ms-transform: scale(0.3); /* IE9 */
		-o-transform: scale(0.3); /* Opera 10.5+ */
		transform: scale(0.3);
		transition: all 0.2s ease-in-out;
	}

	@media only screen and (max-width: 576px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;
	}

	@media only screen and (max-width: 480px) {
		-webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
		-moz-transform: scale(0.2); /* FF3.5+ */
		-ms-transform: scale(0.2); /* IE9 */
		-o-transform: scale(0.2); /* Opera 10.5+ */
		transform: scale(0.2);
		transition: all 0.2s ease-in-out;
	}
}

.map-section-image-1 {
	//position: absolute;
	top: 20%;
	left: 30%;
	z-index: 1;
}

.map-section-image-2 {
	//position: absolute;
	top: 10%;
	left: 5%;
	z-index: 1;
}

.map-section-image-3 {
	//position: absolute;
	top: 13%;
	left: -10%;
	z-index: 1;
}

.map-section-image-4 {
	position: absolute;
	top: 40%;
	left: 20%;
	z-index: 1;
}

.map-section-image-5 {
	position: absolute;
	top: -15%;
	left: -15%;
	z-index: 1;
}

.map-section-image-6 {
	position: absolute;
	top: -10%;
	left: 60%;
	z-index: 1;
}

.map-section-image-7 {
	position: absolute;
	top: 40%;
	left: 50%;
	z-index: 1;
}

.map-section-image-8 {
	position: absolute;
	top: 30%;
	left: 30%;
	z-index: 1;
}

.map-section-image-9 {
	position: absolute;
	top: -20%;
	left: 20%;
	z-index: 1;
}

.map-section-image-10 {
	position: absolute;
	top: 0%;
	left: -10%;
	z-index: 1;
}

.map-section-image-11 {
	position: absolute;
	top: 5%;
	left: 40%;
	z-index: 1;
}

.map-section-image-12 {
	position: absolute;
	top: 30%;
	left: 0%;
	z-index: 1;
}

// .map-section-image {
// 	position: absolute;
// 	z-index: 1;
// }

.map-section-carousel-block-image {
	height: 50%;
	width: 50%;
	transition: all .2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.map-section-carousel-block-title {
	text-align: center;
	font-size: 1.5em;
	font-family: 'Satisfy', cursive;
	font-weight: 100;
}

.map-section-instructor-carousel-arrow {
	font-size: 1.8em;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.3);
	}
}

.map-section-instructor-carousel-column {
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}
}

.map-section-instructor-carousel-header {
	margin-bottom: 20px;
}

.map-section-background-image {
	display: block !important;
	height: 100% !important;
}

.map-section-image-background {
	width: 100% !important;
	max-width: 100% !important;
	height: auto !important;
}

.rec.rec-arrow {
	background-color: transparent;
	color: black !important;

	&:hover {
		background-color:  #7de3df !important;
		color: black !important;
	}

	&:focus {
		background-color:  #7de3df !important;
		color: black !important;
	}
}

.rec-dot {
	background-color: transparent !important;
	box-shadow: none !important;
}

#outer {
	position: relative;
}

#inner {
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.parent {
	height: 100%;
	max-width: 100vw;
	border: 1px black solid;
	position: absolute;
	top: 0%;
	left: 0%;
}

.container-map {
	height: 500px;
	// height: 100%;
	// max-width: 100vh;
	// height: min-content;
	// max-height: fit-content;
	border: 1px red solid;
	position: relative;
	top: 0%;
	left: 35%;
}

img.ri {
	position: relative;
	max-width: 25%;
	// width: 10% !important;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	cursor: pointer;

	transform: scale(0.4);

	&:hover {
		transform: scale(0.55);
	}

	&:focus {
		transform: scale(0.25);
	}

}

@imgscale-xxxl: 0.12;
@imgscale-xxl: 0.12;
@imgscale-xl: 0.12;
@imgscale-lg: 0.13;
@imgscale-md: 0.13;
@imgscale-sm: 0.12;
.instructor-img-wrapper {
	&:hover {
		z-index: 5;
	}

	-webkit-transform: scale(@imgscale-xxxl); /* Saf3.1+, Chrome */
	-moz-transform: scale(@imgscale-xxxl); /* FF3.5+ */
	-ms-transform: scale(@imgscale-xxxl); /* IE9 */
	-o-transform: scale(@imgscale-xxxl); /* Opera 10.5+ */
	transform: scale(@imgscale-xxxl);
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(0.2);
	}

	&:focus {
		transform: scale(0.2);
	}

	@media only screen and (max-width: 1600px) {
		-webkit-transform: scale(@imgscale-xxxl); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-xxxl); /* FF3.5+ */
		-ms-transform: scale(@imgscale-xxxl); /* IE9 */
		-o-transform: scale(@imgscale-xxxl); /* Opera 10.5+ */
		transform: scale(@imgscale-xxxl);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 1200px) {
		-webkit-transform: scale(@imgscale-xxl); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-xxl); /* FF3.5+ */
		-ms-transform: scale(@imgscale-xxl); /* IE9 */
		-o-transform: scale(@imgscale-xxl); /* Opera 10.5+ */
		transform: scale(@imgscale-xxl);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 992px) {
		-webkit-transform: scale(@imgscale-xl); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-xl); /* FF3.5+ */
		-ms-transform: scale(@imgscale-xl); /* IE9 */
		-o-transform: scale(@imgscale-xl); /* Opera 10.5+ */
		transform: scale(@imgscale-xl);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 768px) {
		-webkit-transform: scale(@imgscale-lg); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-lg); /* FF3.5+ */
		-ms-transform: scale(@imgscale-lg); /* IE9 */
		-o-transform: scale(@imgscale-lg); /* Opera 10.5+ */
		transform: scale(@imgscale-lg);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 576px) {
		-webkit-transform: scale(@imgscale-md); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-md); /* FF3.5+ */
		-ms-transform: scale(@imgscale-md); /* IE9 */
		-o-transform: scale(@imgscale-md); /* Opera 10.5+ */
		transform: scale(@imgscale-md);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}

	@media only screen and (max-width: 480px) {
		-webkit-transform: scale(@imgscale-sm); /* Saf3.1+, Chrome */
		-moz-transform: scale(@imgscale-sm); /* FF3.5+ */
		-ms-transform: scale(@imgscale-sm); /* IE9 */
		-o-transform: scale(@imgscale-sm); /* Opera 10.5+ */
		transform: scale(@imgscale-sm);
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(0.2);
		}

		&:focus {
			transform: scale(0.2);
		}
	}
}

.instructor-img {
	width: 100%;
	height: 100%;
}

.map-section-carousel-block-div {
	cursor: pointer;
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;