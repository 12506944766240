.home-page-container {
	font-family: 'Montserrat', sans-serif;
}

.landing-section {
	height: 100vh;

	@media only screen and (max-width: 992px) {
		height: auto;
	}
}

.landing-section-divider {
	width: 30%;
	height: 2px;
	background-color: #02d1c7;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
	}
}

.landing-section-title {
	font-size: 3.5em;
	font-weight: bold;

	@media only screen and (max-width: 768px) {
		font-size: 2em;
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

.landing-section-subtitle {
	font-size: 1em;
	font-weight: 400;
	padding-bottom: 30px;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

.landing-section-description {
	width: 70%;
	font-size: 1.3em;
	font-weight: 400;
	padding-bottom: 30px;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

.landing-section-button {
	background-color: #02d1c7;
	width: 230px;
	height: 50px;
	border-radius: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 1.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.landing-section-image {
	width: 70%;
	height: 50%;

	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.products-section {
	background-color: #f0f0f094;

	padding-bottom: 100px;
}

.products-section-image {
	width: 70%;
	height: auto;

	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-top: 80px;
	}
}

.products-section-column {
	text-align: center;
}

.products-section-title {
	font-weight: bold;
	font-size: 1.5em;
}

.products-section-divider {
	height: 1px;
	background-color: black;
	width: 30%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 15px;
}

.products-section-button {
	background-color: #02d1c7;
	width: 150px;
	height: 30px;
	border-radius: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.products-section-bottom-row {
	margin-top: 100px;

	@media only screen and (max-width: 992px) {
		margin-top: 0px;
	}
}

.about-section {
	margin-top: 100px;
}

.about-section-image {
	width: 70%;
	height: 70%;

	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.about-section-title {
	width: 80%;
	font-size: 2.8em;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	@media only screen and (max-width: 768px) {
		font-size: 2em;
	}
}

.about-section-description {
	width: 70%;
	font-size: 0.9em;
	font-weight: 400;
	padding-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	line-height: 1.7em;
	margin-top: 15px;
}

.about-section-divider {
	width: 60%;
	height: 1px;
	background-color: black;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
}

.about-section-quote {
	width: 70%;
	font-size: 0.9em;
	font-weight: 400;
	padding-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	line-height: 1.7em;
	margin-top: 30px;
}

.about-section-read-more {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-decoration: underline;
	cursor: pointer;
}

.about-section-items-title {
	font-weight: bold;
	font-size: 1.5em;
	@media only screen and (max-width: 992px) {
		font-size: 1em;
	}
}

.about-section-items-description {
	@media only screen and (max-width: 992px) {
		font-size: 0.8em;
	}
}

.about-section-bottom-row {
	margin-top: 100px;
}

.about-section-items-image {
	max-width: 70%;
	max-height: 70%;

	@media only screen and (max-width: 992px) {
		max-width: 70%;
		max-height: 70%;
	}
}

.about-section-items-column {
	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.about-product-section {
	margin-top: 200px;
	background-color: #f0f0f0;
	padding-bottom: 100px;
	padding-top: 100px;
}

.about-product-section-title {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-weight: bold;
	font-size: 2em;
	@media only screen and (max-width: 992px) {
		font-size: 1em;
	}
}

.about-product-section-items-title {
	font-weight: bold;
	font-size: 1.5em;
	@media only screen and (max-width: 992px) {
		font-size: 1em;
	}
}

.about-product-section-items-description {
	@media only screen and (max-width: 992px) {
		font-size: 0.8em;
	}
}

.about-product-top-row {
	margin-top: 70px;

	@media only screen and (max-width: 992px) {
		margin-top: 0px;
	}
}

.about-product-bottom-row {
	margin-top: 70px;

	@media only screen and (max-width: 992px) {
		margin-top: 0px;
	}
}

.about-product-section-section-items-image {
	width: 70%;
	height: 70%;

	@media only screen and (max-width: 992px) {
		width: 50%;
	}
}

.about-product-section-items-column {
	@media only screen and (max-width: 992px) {
		margin-top: 30px;
	}
}

.trainings-section-row-top {
	padding-top: 50px;
	padding-bottom: 50px;
}

.trainings-section-row-bottom {
	padding-top: 50px;
	padding-bottom: 50px;
}

.trainings-section-row-top-title {
	font-size: 2em;
	font-weight: bold;
}

.trainings-section-row-top-image {
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 40px;
}

.trainings-section-row-top-description {
	text-align: center;
}

.trainings-section-row-bottom-image {
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 40px;
}

.trainings-section-row-bottom-title {
	text-align: center;
	font-weight: bold;
}

.trainings-section-row-bottom-description {
	text-align: center;
	margin-top: 15px;
}

.trainings-enrolment-section-row-top-image {
	margin-bottom: 50px;
}

.trainings-enrolment-section-row-top-divider {
	width: 100%;
	height: 1px;
	background-color: black;
	top: 50%;
}

.trainings-enrolment-section-row-top-title {
	font-size: 2em;
	font-weight: bold;
	text-align: center;

	@media only screen and (max-width: 992px) {
		font-size: 1.2em;
	}
}

.trainings-enrolment-section-row-top-description {
	text-align: center;
	font-size: 0.8em;
	margin-top: 10px;
}

.trainings-enrolment-section-row-top-row-divider {
	height: 100%;
}

.trainings-enrolment-section-row-bottom-image {
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 40px;
	height: 130px;
}

.trainings-enrolment-section-row-bottom-title {
	text-align: center;
	font-weight: bold;
	color: #02d1c7;
	font-size: 1.5em;
}

.trainings-enrolment-section-row-bottom-description {
	text-align: center;
	height: 100px;
	margin-top: 15px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 30px;
}

.trainings-enrolment-section-column {
	background-color: rgba(0, 0, 0, 0.06);
	border-radius: 30px;
	box-shadow: 1px 5px 50px rgba(0, 0, 0, 0.1);

	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.trainings-enrolment-section-row-top {
	padding-top: 50px;
	padding-bottom: 50px;
}

.trainings-enrolment-section-row-bottom {
	padding-top: 50px;
	padding-bottom: 50px;
}

.trainings-enrolment-section-new-row {
	width: 100%;
}

.trainings-enrolment-section-new-row-header {
	margin-top: 20px;
	margin-bottom: 20px;
	font-weight: bold;
}

.trainings-enrolment-section-button {
	color: #02d1c7;
	border-color: #02d1c7;
	border-style: solid;
	border-width: 2px;
	margin-right: auto;
	margin-left: auto;
	width: 230px;
	height: 50px;
	border-radius: 50px;
	font-weight: bold;
	text-align: center;
	font-size: 1.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin-bottom: 50px;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.new-products-section {
	margin-top: 100px;
	background-color: #f0f0f0;
	padding-top: 50px;
	padding-bottom: 50px;
}

.new-products-section-row-top-divider {
	width: 100%;
	height: 1px;
	background-color: black;
	top: 50%;
}

.new-products-section-row-top-title {
	font-size: 2em;
	font-weight: bold;
	text-align: center;

	@media only screen and (max-width: 992px) {
		font-size: 1.2em;
	}
}

.new-products-section-row-top-description {
	text-align: center;
	font-size: 0.8em;
	margin-top: 10px;
}

.new-products-section-row-top-row-divider {
	height: 100%;
}

.new-products-items-row {
	margin-top: 100px;
}

.new-products-section-image {
	width: 50%;
	height: 50%;

	@media only screen and (max-width: 768px) {
		margin-top: 50px;
	}
}

.new-products-section-name {
	font-weight: bold;
	font-size: 1.4em;
	margin-top: 20px;
}

.new-products-section-price {
	margin-top: 10px;
	font-size: 1.2em;
}

.special-offer-section {
	margin-top: 100px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.special-offer-section-row-top-divider {
	width: 100%;
	height: 1px;
	background-color: black;
	top: 50%;
}

.special-offer-section-row-top-title {
	font-size: 2em;
	font-weight: bold;
	text-align: center;

	@media only screen and (max-width: 992px) {
		font-size: 1.2em;
	}
}

.special-offer-section-row-top-description {
	text-align: center;
	font-size: 0.8em;
	margin-top: 10px;
}

.special-offer-section-row-top-row-divider {
	height: 100%;
}

.special-offer-items-row {
	margin-top: 100px;
}

.special-offer-section-image {
	width: 50%;
	height: 50%;
	max-height: 350px;
	max-width: 290px;
	object-fit: contain;

	@media only screen and (max-width: 768px) {
		margin-top: 50px;
	}
}

.special-offer-section-name {
	font-weight: bold;
	font-size: 1.4em;
	margin-top: 20px;
}

.special-offer-section-price {
	margin-top: 10px;
	font-size: 1.2em;
}

.special-offer-section-button {
	background-color: #02d1c7;
	width: 170px;
	height: 30px;
	border-radius: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.special-offer-product-column {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 30px;
	box-shadow: 1px 5px 50px rgba(0, 0, 0, 0.18);
	padding-top: 30px;
	padding-bottom: 30px;

	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.map-section-current-trainings-button {
	background-color: #02d1c7;
	width: 300px;
	height: 50px;
	border-radius: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 1.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin-bottom: 100px;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.footer-section-main-row {
	margin-top: 100px;
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.footer-section-inner-divider {
	width: 20%;
	height: 2px;
	background-color: #02d1c7;
	margin-top: 7px;
	margin-bottom: 20px;
}

.footer-section-inner-divider-row {
	@media only screen and (max-width: 768px) {
		justify-content: center;
	}
}

.footer-section-inner-img {
	width: 50%;
	height: auto;
}

.footer-section-inner-header {
	font-size: 1.1rem;

	@media only screen and (max-width: 768px) {
		margin-top: 50px;
		justify-content: center;
	}
}

.footer-section-inner-text-link {
	font-size: 0.8rem;
	margin-top: 5px;
	color: rgba(0, 0, 0, 0.5);
	transition: all 0.2s ease-in-out;

	&:hover {
		cursor: pointer;
		color: rgba(0, 0, 0, 0.8);
	}

	@media only screen and (max-width: 768px) {
		justify-content: center;
	}
}

.footer-section-inner-text {
	font-size: 0.8rem;
	margin-top: 5px;
	color: rgba(0, 0, 0, 0.5);

	@media only screen and (max-width: 768px) {
		justify-content: center;
	}
}

@primary-color: #02d1c7;@menu-collapsed-width: 40px;