.bg-site {
  background-color: white !important;
}
.content-layout {
  background: #fff;
  min-height: calc(100vh - 64px);
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.w-fit {
  width: fit-content !important;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar-track {
  border: 0.1px solid lightgray;
  border-radius: 0.25em;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}
.fixed-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.contact-page-container {
  min-height: 100vh;
}
.contact-page-container-header {
  margin-top: 50px;
  margin-bottom: 100px;
  font-size: 3em;
}
.contact-page-container-section-header {
  font-size: 2em;
}
@media only screen and (max-width: 992px) {
  .contact-page-container-section-header span {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}
.contact-page-container-section-divider {
  height: 2px;
  width: 100px;
  background-color: #02D1C7;
  margin-bottom: 50px;
}
@media only screen and (max-width: 992px) {
  .contact-page-container-section-divider {
    margin-left: auto;
    margin-right: auto;
  }
}
.contact-page-container-section-content {
  margin-top: 3px;
  margin-bottom: 3px;
}
@media only screen and (max-width: 992px) {
  .contact-page-container-section-content span {
    margin-left: auto;
    margin-right: auto;
  }
}
.contact-page-container-content-header {
  font-weight: 600;
  margin-top: 20px;
}
