
.trainings-page-container-title {
	font-size: 2.5em;
    margin-top: 100px;
}

.trainings-page-container-image-divider {
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 40px;
}

.trainings-page-container-description {
    text-align: center;
    padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 100px;
}

.trainings-page-container-collapse {
	width: 80%;

	@media only screen and (max-width: 420px) {
		width: 100%;
	}
}

.trainings-page-container-collapse-button {
	border-color: #02d1c7;
	border-style: solid;
	border-width: 1px;
	color: #02d1c7;
	width: 150px;
	height: 40px;
	border-radius: 10px;
	font-weight: bold;
	text-align: center;
	font-size: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
		background-color: #02d1c7;
		color: white;
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.trainings-page-container-collapse-row {
	height: 100%;
}

.trainings-page-container-collapse-date {
	font-weight: bold;
}

.trainings-page-container-collapse-description {
	@media only screen and (max-width: 768px) {
		margin-top: 20px;
	}
}

.trainings-page-container-collapse-inner-col {
	padding-left: 10px;
}

.trainings-page-container-collapse-trainer-image {
	max-width: 50%;
	max-height: 50%;
	padding-right: 10px;

	@media only screen and (max-width: 768px) {
		max-width: 30%;
		max-height: 30%;
	}
}

.trainings-page-container-collapse-inner-row {
	margin-top: 30px;
}

.trainings-page-container-modal-title {
	margin-bottom: 30px;
}

.trainings-page-container-modal-description {
	margin-top: 30px;
	text-align: center;
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;