.brow-bomber-page-container {
    margin-top: 100px;
    margin-left: 50px;
    margin-right: 50px;
}

.brow-bomber-page-container-title {
    font-size: 2.5em;
}

.brow-bomber-page-container-title-divider {
	width: 20%;
	height: 2px;
    background-color: #02d1c7;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
	}
}

.brow-bomber-page-container-subtitle {
	@media only screen and (max-width: 768px) {
		text-align: center;
	}
}

.brow-bomber-page-container-button {
    margin-top: 40px;
    background-color: #02d1c7;
	width: 230px;
	height: 50px;
	border-radius: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 1.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		width: 180px;
		height: 40px;
		font-size: 1em;
	}
}

.brow-bomber-page-container-description-title {
	font-size: 1.5em;
	
	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.brow-bomber-page-container-description-divider {
    width: 20%;
	height: 2px;
    background-color: #02d1c7;
    margin-bottom: 30px;
    margin-top: 10px;
}

.brow-bomber-page-container-details-row {
    margin-top: 50px;
    margin-bottom: 50px;
}

.brow-bomber-page-container-description-element {
    width: 100%;
}

.brow-bomber-page-container-footer-row {
    margin-top: 50px;
    margin-bottom: 50px;
}


.brow-bomber-page-container-footer-title {
	font-size: 1.5em;
	
	@media only screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.brow-bomber-page-container-footer-divider {
    width: 20%;
	height: 2px;
    background-color: #02d1c7;
    margin-bottom: 30px;
    margin-top: 10px;
}

.brow-bomber-page-container-footer-element {
    width: 80%;
}

.brow-bomber-page-container-landing-image {
	max-height: 30%;
	max-width: 30%;

	@media only screen and (max-width: 992px) {
		margin-top: 100px;

		max-height: 90%;
		max-width: 90%;
	}
}
@primary-color: #02d1c7;@menu-collapsed-width: 40px;