
.instructor-details-main-header {
    margin-top: 50px;
    font-size: 2rem;
}

.instructor-details-description-header {
    font-size: 2rem;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
        text-align: center;
        justify-content: center;
	}
}

.instructor-details-description-text {
    font-size: 1rem;

    @media only screen and (max-width: 768px) {
        text-align: center !important;
        justify-content: center;
	}
}

.instructor-details-description-list {
    margin-top: 30px;
    font-weight: bold;
    font-size: 1rem;

    @media only screen and (max-width: 768px) {
        text-align: center !important;
        justify-content: center;
	}
}

.instructor-data-header-divider {
    width: 5%;
	height: 2px;
    background-color: #02d1c7;
    margin-left: auto;
    margin-right: auto;

	@media only screen and (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
    }
    
    @media only screen and (max-width: 768px) {
        margin-bottom: 50px;
	}
}

.instructor-data-description-divider {
    width: 20%;
	height: 2px;
    background-color: #02d1c7;
    margin-bottom: 30px;

	@media only screen and (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.instructor-details-social-icon {
    color: #02d1c7 !important;
    font-size: 1.6rem;
    margin-right: 10px;
}


.instructor-details-social-item {

    @media only screen and (max-width: 992px) {
        margin-top: 20px;
	}
}


@primary-color: #02d1c7;@menu-collapsed-width: 40px;